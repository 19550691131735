
<v-slide-group
  v-if="shouldUseCarousel"
  @click:next="incrementCenteredItem"
  @click:prev="decrementCenteredItem"
  center-active
  v-model="centeredItem"
  show-arrows="always"
  :key="'carousel-' + renderMe"
>
  <div :style="carouselSubgridContainerStyles" class="subgrid-container-carousel">
    <SubHeader
      v-if="offersPageSubHeader"
      :icon="offersPageSubHeader.icon"
      :largeText="offersPageSubHeader.largeText"
      :smallText="offersPageSubHeader.smallText"
    ></SubHeader>
    <v-slide-item :key="i" v-for="(p, i) in filteredPackages">
      <div :class="packageItemClass(i, p)">
        <PackageNew
          v-if="!broadbandLabelPlacement || broadbandLabelPlacement !== 'standard'"
          :class="isFlipped[i] ? 'flipped-package' : ''"
          :packageData="p"
          :colorIndex="i"
          :displayNameTextColor="displayNameTextColor(i)"
          :hasUnderPriceText="hasUnderPriceText"
          :broadbandLabelPlacement="broadbandLabelPlacement"
          @packageSelected="packageSelected"
          @labelCTAClicked="toggleBroadbandLabel(i, p)"
          :labelHtml="getLabelHtmlForPackageName(p.Name)"
        ></PackageNew>
        <BroadbandLabelBase
          :class="isFlipped[i] ? 'flipped-label' : ''"
          v-if="shouldShowLabel(i, p.Name)"
          :shopper="shopper"
          :packageData="p"
          :colorIndex="i"
          :selectButtonInLabel="true"
          :closeButtonInLabel="broadbandLabelPlacement !== 'standard' && broadbandLabelPlacement !== 'below'"
          :labelHtml="getLabelHtmlForPackageName(p.Name)"
          @labelClosed="toggleBroadbandLabel(i)"
          @packageSelected="packageSelected"
        ></BroadbandLabelBase>
      </div>
    </v-slide-item>
  </div>
</v-slide-group>
<div v-else :style="subgridContainerColumnStyles" class="subgrid-container">
  <SubHeader
    v-if="offersPageSubHeader"
    :icon="offersPageSubHeader.icon"
    :largeText="offersPageSubHeader.largeText"
    :smallText="offersPageSubHeader.smallText"
  ></SubHeader>
  <template v-for="(p, i) in filteredPackages">
    <div :key="i" :class="packageItemClass(i, p)">
      <PackageNew
        :class="isFlipped[i] ? 'flipped-package' : ''"
        :packageData="p"
        :colorIndex="i"
        :displayNameTextColor="displayNameTextColor(i)"
        :hasUnderPriceText="hasUnderPriceText"
        :broadbandLabelPlacement="broadbandLabelPlacement"
        @packageSelected="packageSelected"
        @labelCTAClicked="toggleBroadbandLabel(i, p)"
        :labelHtml="getLabelHtmlForPackageName(p.Name)"
        :isLabelExpanded="isExpanded[i] || isFlipped[i]"
      ></PackageNew>
      <BroadbandLabelBase
        :class="isFlipped[i] ? 'flipped-label' : ''"
        :id="`broadband-label-${i + 1}`"
        v-if="shouldShowLabel(i, p.Name)"
        :shopper="shopper"
        :packageData="p"
        :colorIndex="i"
        :selectButtonInLabel="true"
        :closeButtonInLabel="broadbandLabelPlacement !== 'standard' && broadbandLabelPlacement !== 'below'"
        :labelHtml="getLabelHtmlForPackageName(p.Name)"
        @labelClosed="toggleBroadbandLabel(i)"
        @packageSelected="packageSelected"
      ></BroadbandLabelBase>
    </div>
  </template>
  <div v-if="offerCardFooterItems" class="offer-footer">
    <template v-for="offerCardFooterItem in offerCardFooterItems">
      <div v-html="offerCardFooterItem"></div>
    </template>
  </div>
</div>
