import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { getConfigBoolean, getConfigItem, getConfigString } from './../../shared/getConfigItem'
import {
  ChargeTypeKey,
  FeedItem,
  FeeGroup,
  ShoppingOrder,
  PricedItem,
  Item,
  ConfigKeys,
  smartSum,
  getItemPriceAsNumber,
  getItemPrice,
  shouldPriceIntoParent,
  getItemFeesRecursive,
  CartTotal,
  getPromoProgressions,
  OrderPackage,
  newOrderPackage,
  ShoppingCartPhone,
  hasFees
} from '@adg/catalog/src/modules/Catalog'

import { computed, ComputedRef, watch } from '@vue/composition-api'
import { flatten, has } from 'lodash'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import creditCheck from '@/components/shared/creditCheck/useCreditCheck'
import { Fee, Package } from '@adg/catalog/src/modules/Catalog'
import { GET_CURRENT_STEP } from '@/store/types'
import { Cart, CartPhone, StoreOrder } from '@adg/catalog/src/modules/Order'
import { uiMacroParser } from '@/utils/ShopperHelpers'

export default ($store, computedCart?: ComputedRef<Cart>) => {
  const promo = computed(() => $store.getters.getPromo)
  const selectedPackage = computed(() => $store.getters.getPackage as Package)
  const order = computed(() => $store.getters.getOrder as StoreOrder)
  const shopper = computed(() => $store.getters.getShopper as IShopper)
  const cart = computed(() => computedCart?.value ?? ($store.getters.getCart as Cart))
  const phone = computed(() => $store.getters.getPhone as CartPhone)
  const { hasBadCredit, mustPrePay } = creditCheck()
  const currentStep = computed(() => $store.getters[GET_CURRENT_STEP])

  // KWC now dynamically find all the Fee Groups defined in the Fees, instead of using what is in the catalog RefData
  const feeGroups = computed(() =>
    allFees.value
      .map((f) => f['Fee Group'])
      .filter(exists)
      .filter(distinct)
  )

  const promoAvailable = computed(() => (selectedPackage?.value?.Promos ? selectedPackage.value.Promos.length > 0 : false))

  const quantityOfCharge = (chargeName: string) => {
    return monthlyCharges.value.filter((c) => c.Name === chargeName).length
  }

  // todo: figure out type for value in distinct
  const distinct = (value: any, index: number, self: any[]) => self.indexOf(value) === index
  const exists = <T>(value: T): value is NonNullable<T> => value !== null && value !== undefined

  const getAllDisclaimers = computed(() => {
    const disclaimers = cartItems.value
      .map((cartItem: FeedItem) => {
        const cartItemWithFees: FeedItem[] = [cartItem]
        if (hasFees(cartItem)) {
          const fees = getItemFeesRecursive(cartItem, 1, true)
          fees.forEach((fee) => cartItemWithFees.push(fee))
        }
        return cartItemWithFees
      })
      .flat()
      .map((ci) => ci.Disclaimer)
      .filter(exists)
      .filter(distinct)
    return disclaimers
  })

  const cartItems = computed(() => {
    let ret: FeedItem[] = flatten([
      cart.value.package ?? [],
      [...(cart.value.package.Products ?? [])],
      [...cart.value.tv.upgrades],
      [...cart.value.tv.equipment],
      [...cart.value.phone.equipment],
      [...cart.value.phone.upgrades],
      [...cart.value.internet.upgrades],
      [...cart.value.internet.equipment],
      [...cart.value.packageUpgrades],
      [...cart.value.homeAutomation.upgrades],
      [...cart.value.homeAutomation.equipment],
      cart.value.promo ?? []
    ])
    $store.commit('setCartItems', ret)
    return ret
  })

  const orderId = computed(() => (order.value ? order.value.id : 0))

  const schedule = computed(() => (cart.value ? cart.value.schedule : undefined))

  const allFees = computed((): Fee[] => {
    const fees = flatten(
      cartItems.value
        .filter((i) => notIncluded(i))
        .filter((i) => !shouldPriceIntoParent(i))
        .map((i) => getItemFeesRecursive(i, 1))
    )
    return fees
  })

  const allFeesEvenPricedIntoParent = computed((): Fee[] => {
    const fees = flatten(
      cartItems.value
        .filter((i) => (getConfigBoolean(ConfigKeys.payIncludePackageFees) ? true : i.itemType !== 'Package'))
        .filter((i) => notIncluded(i))
        .map((i) => {
          return getItemFeesRecursive(i, 1, true)
        })
    )
    return fees
  })

  const itemsAndFees = computed(() => flatten([[...cartItems.value], [...allFees.value]]))

  const groupedEitherFees = (chargeType: ChargeTypeKey): FeeGroup[] => {
    let ret: FeeGroup[] = Array()
    let groups = feeGroups.value || []

    groups.forEach((fg) => {
      let g: FeeGroup = {
        Name: fg,
        itemType: 'FeeGroup',
        //Fees: allFees.value.filter((f) => f['Fee Group'] === fg).filter((f) => f[chargeType])
        Fees: allFees.value.filter((f) => f['Fee Group'] === fg)
      }
      g.FeeType = g.Fees[0].FeeType //all feetypes in a feegroup should be the same // we don't enforce this...
      g.expandable = g.Fees && g.Fees.find((f) => f.expandable) ? true : false

      g[chargeType] = g.Fees.map((f) => getItemPrice(f, chargeType)).reduce((a, b) => smartSum(a, b), 0)
      // Add group to the array if non-zero fees
      if (g[chargeType] && g[chargeType] != 0) {
        // set Rank to lowest rankVal of all Fees
        g.Rank = g.Fees.map((f) => rankval(f)).reduce((a, b) => (a < b ? a : b), Number.MAX_SAFE_INTEGER)
        ret.push(g)
      }
    })
    return ret
  }

  const groupedRecurringFees = computed(() => groupedEitherFees('Monthly Price'))
  const groupedNonRecurringFees = computed(() => groupedEitherFees('OTC'))

  const unGroupedEitherFees = (chargeType: ChargeTypeKey): Fee[] => {
    let ret: Fee[] = []
    let feegroups = feeGroups.value || []
    ret = allFees.value.filter((f) => !feegroups.includes(f['Fee Group'] || '')).filter((f) => getItemPrice(f, chargeType))
    return ret
  }

  const unGroupedRecurringFees = computed(() => unGroupedEitherFees('Monthly Price'))
  const unGroupedNonRecurringFees = computed(() => unGroupedEitherFees('OTC'))

  const isFeeType = (type: string): boolean => type === 'Fee' || type === 'FeeGroup'

  const monthlyNoFees = computed(() => {
    let nofees = monthlyCharges.value
      .filter((mc) => !isFeeType(mc.itemType))
      .filter((mc) => getItemPrice(mc, 'Monthly Price') !== undefined)
    return nofees
  })

  const monthlyFees = computed(() => {
    let fees = monthlyCharges.value.filter((mc) => isFeeType(mc.itemType)).filter((mc) => mc['Monthly Price'] !== undefined)
    return fees
  })

  const hasCharges = (item: FeedItem, chargeType: ChargeTypeKey): boolean => {
    if (shouldPriceIntoParent(item)) return false
    return item[chargeType] !== undefined || getItemPriceAsNumber(item, chargeType) != 0
  }
  const addRequiredPackage = (f: Fee, chargeType: ChargeTypeKey) => chargeType === 'Monthly Price' && f.itemType === 'Package' // always add packages to monthly fees, even if they have no price

  const EitherCharge = (chargeType: ChargeTypeKey): FeedItem[] => {
    let lineItems: FeedItem[] = []
    const charges = itemsAndFees.value.filter((u) => hasCharges(u, chargeType) || addRequiredPackage(u, chargeType)) as FeedItem[] // packages are always required for monthly fees
    for (let charge of charges) {
      if (charge.qty === undefined) {
        lineItems.push(charge)
      } else if (charge.qty > 0) {
        lineItems = [...lineItems, ...Array(charge.qty).fill({ ...charge, qty: 1 })]
      }
    }
    return lineItems
  }

  const isNumeric = (o: any): boolean => {
    return o !== undefined && typeof o === 'number' && !isNaN(o)
  }

  type ProrationFunction = () => number
  type ProrationFunctions = { [key: string]: ProrationFunction }
  type ProrationCondition = () => boolean
  type ProrationConditions = { [key: string]: ProrationCondition }

  const customProrationFunctions: ProrationFunctions = {
    MetronetProrationFee: function () {
      const mcs = monthlyCharges.value
      const proRation = mustPrePay.value ? -2 / 30 : 1 / 6
      return proRation * mcs.map((mc) => getItemPriceAsNumber(mc, 'Monthly Price')).reduce((a, b) => a + b, 0)
    }
  }

  const customProrationConditions: ProrationConditions = {
    requiresPayment: () => mustPrePay.value
  }

  const prorationCondition = (name?: string): boolean => {
    if (name && customProrationConditions[name]) return customProrationConditions[name]()
    return true
  }

  const prorationFees = computed((): Fee[] => {
    const prorationFunctionName = getConfigString(ConfigKeys.prorationFunction)
    const prorationConditionName = getConfigString(ConfigKeys.prorationCondition)
    const fees: Fee[] =
      prorationFunctionName && prorationCondition(prorationConditionName) && customProrationFunctions[prorationFunctionName]
        ? [
            {
              Name: getConfigString(ConfigKeys.prorationName) ?? 'Prorated Monthly Fee',
              itemType: 'Fee',
              OTC: customProrationFunctions[prorationFunctionName]()
            }
          ]
        : []
    return currentStep.value >= 5 ? fees : []
  })

  const rankval = (item: Item): number => {
    let rank = item.Rank
    const avgRank = Number.MAX_SAFE_INTEGER / 2
    if (typeof rank === 'undefined') {
      rank = avgRank
    } else if (typeof rank === 'number') {
    } else if (typeof rank === 'string') {
      rank = rank.match(/\s*/) ? avgRank : Number(rank)
      rank = isNaN(rank) ? avgRank : rank
    }
    if (rank < 0) {
      rank = Number.MAX_SAFE_INTEGER + rank
    }
    return rank
  }

  const monthlyCharges = computed(() => {
    const ret = [
      ...EitherCharge('Monthly Price').filter((c) => !isFeeType(c.itemType)),
      ...unGroupedRecurringFees.value,
      ...groupedRecurringFees.value
    ]
    return ret.sort((a, b) => rankval(a) - rankval(b))
  })
  const oneTimeCharges = computed(() => {
    const ret = [
      ...EitherCharge('OTC').filter((c) => !isFeeType(c.itemType)),
      ...unGroupedNonRecurringFees.value,
      ...groupedNonRecurringFees.value,
      ...prorationFees.value
    ].filter((i) => notIncluded(i))

    return ret.sort((a, b) => rankval(a) - rankval(b))
  })

  const sumup = (sum: number, amnt: number): number => sum + amnt

  const notIncluded = (item: FeedItem): boolean => {
    return !(item.priceIncluded || item['Monthly Price'] === 'Included')
  }

  const eitherTotal = (chargeType: ChargeTypeKey): number => {
    return EitherCharge(chargeType)
      .filter((ci) => ci && notIncluded(ci))
      .map((ci) => ci && getItemPriceAsNumber(ci, chargeType))
      .reduce(sumup, 0)
  }

  const monthlyTotal = computed(() =>
    monthlyCharges.value
      //.filter((ci) => ci && notIncluded(ci))
      .map((ci) => ci && getItemPriceAsNumber(ci, 'Monthly Price'))
      .reduce(sumup, 0)
  )

  const oneTimeTotal = computed(() =>
    oneTimeCharges.value
      //.filter((ci) => ci && notIncluded(ci))
      .map((ci) => ci && getItemPriceAsNumber(ci, 'OTC'))
      .reduce(sumup, 0)
  )

  // need to handle later month downpayments
  const downPaymentFees = computed(() =>
    allFeesEvenPricedIntoParent.value.filter((f) => ['both', 'downpayment'].includes(f.FeeType ?? 'none'))
  )
  const reoccurringPaymentFees = computed(() =>
    allFeesEvenPricedIntoParent.value.filter((f) => ['both', 'reoccurring', 'recurring'].includes(f.FeeType ?? 'none'))
  )

  const downPaymentTotal = computed(() => {
    return getConfigItem(ConfigKeys.paymentConfig).addMonthlyChargesToDownPayment ? grandTotal.value : oneTimeTotal.value
  })
  const recurringTotal = computed(() => {
    return monthlyTotal.value
  })

  const grandTotal = computed((): number => {
    return monthlyTotal.value + oneTimeTotal.value
  })

  const cartTotal = computed(() => {
    const total = {
      itemType: 'CartTotal',
      subItems: monthlyCharges.value.map((i) => {
        return { ...i, PriceIntoParent: true }
      }),
      Name: 'Monthly Total',
      expandable: false
    } as CartTotal
    return total
  })

  const removePromo = () => $store.commit('removePromo')

  const shoppingCart = computed(() => {
    const buildPhone = (): ShoppingCartPhone => {
      if (phone.value.Name === 'New') {
        return { ...phone.value.upgrades[0], phoneNumberDetails: phone.value.phoneNumberDetails }
      } else {
        return {
          ...phone.value.upgrades[0],
          phoneNumber: phone.value.phoneNumber,
          phoneNumberDetails: phone.value.phoneNumberDetails
        }
      }
    }

    const pkg = (): OrderPackage => {
      return selectedPackage.value
        ? {
            Name: selectedPackage.value.Name ?? '',
            'Display Name': selectedPackage.value['Display Name'] ?? '',
            Price: coreCurrency(getItemPrice(selectedPackage.value, 'Monthly Price')),
            'Product Types':
              selectedPackage.value.Products.map((p) => p['Product Type']).reduce(
                (a: string[], b: string | undefined): string[] => (b !== undefined ? a.concat(b) : a),
                []
              ) ?? [],
            'Billing Codes': selectedPackage.value['Billing Codes'],
            Disclaimer: selectedPackage.value['Disclaimer'],
            itemType: selectedPackage.value.itemType
          }
        : newOrderPackage()
    }

    const products = selectedPackage.value['Products']?.map((p) => {
      const { Upgrades, Equipment, ...everythingElse } = p
      return { ...everythingElse }
    })

    const itemPrice = (item: FeedItem, key: ChargeTypeKey): string => {
      return item.priceIncluded ? 'Included' : coreCurrency(getItemPrice(item, key))
    }

    const order: ShoppingOrder = {
      id: orderId.value,
      feeGroups: feeGroups.value ?? [],
      schedule: $store.getters.getCartSchedule,
      items: cartItems.value.filter((i) => i.itemType !== 'Product'),
      package: pkg(),
      products: products,
      phone: phone.value && phone.value.upgrades.length > 0 ? buildPhone() : undefined,
      promo: promo.value ? { ...promo.value, Price: coreCurrency(promo.value['Price']) } : undefined,
      monthlyCharges: monthlyCharges.value
        .filter((mc) => mc.Name !== selectedPackage.value.Name)
        ?.map((mc) => ({
          ...mc,
          'Monthly Price': itemPrice(mc, 'Monthly Price')
        })),
      oneTimeCharges: oneTimeCharges.value?.map((mc) => ({
        ...mc,
        OTC: itemPrice(mc, 'OTC')
      })),
      disclaimers: getAllDisclaimers.value,
      monthlyTotal: coreCurrency(monthlyTotal.value),
      grandTotal: coreCurrency(grandTotal.value),
      oneTimeTotal: coreCurrency(oneTimeTotal.value),
      promoProgressions: getPromoProgressions(cartTotal.value)
    }
    return order
  })

  const orderToSubmit = () => {
    const shopperId = $store.getters.getShopper.id
    return { order: shoppingCart.value, shopperId }
  }
  const nullCheck = (v: Item) => v !== null
  const qtyCheck = (v: Item) => v.qty && v.qty > 0
  const getPrice = (item: PricedItem, priceType: ChargeTypeKey): string | number | undefined => {
    return item.priceIncluded ? 'Included' : getItemPrice(item, priceType)
  }

  const cartHeaderImage = computed(() => getConfigItem(ConfigKeys.cartHeaderImage) ?? 'CartIcon.png')
  const cartHeaderText = computed(() => getConfigItem(ConfigKeys.cartHeaderText) ?? 'YOUR PACKAGE')
  const monthlyTotalText = computed(() => getConfigItem(ConfigKeys.monthlyTotalText) ?? 'Monthly Total')
  const cartHeaderBackgroundColor = computed(() => getConfigItem(ConfigKeys.cartHeaderBackgroundColor) ?? 'secondaryDark')
  const sharpCorners = computed((): Boolean => {
    const configVal = getConfigItem(ConfigKeys.cardSharpCorners)
    if (configVal === null || configVal === undefined) {
      return false
    } else if (typeof configVal === 'boolean') {
      return configVal
    } else {
      return configVal === 'true'
    }
  })
  const monthlyTotalColor = computed(() => getConfigItem(ConfigKeys.monthlyTotalColor) ?? 'primary')
  const displayMonthlyPriceTopOfCart = computed(() => getConfigItem(ConfigKeys.displayMonthlyPriceTopOfCart) ?? true)
  const showCartPackagePlanSection = computed(() => getConfigItem(ConfigKeys.showCartPackagePlanSection) ?? false)
  const showCartDueMonthly = computed(() => getConfigItem(ConfigKeys.showCartDueToday) ?? false)
  const showCartDueToday = computed(() => getConfigItem(ConfigKeys.showCartDueToday) ?? false)
  const cartTotalDueTodayText = computed(() => getConfigItem(ConfigKeys.cartTotalDueTodayText) ?? 'Due today')
  const showCartPromoToggleField = computed(() => getConfigItem(ConfigKeys.showCartPromoToggleField) ?? false)
  const oneTimeChargesLabel = computed((): string => getConfigItem(ConfigKeys.oneTimeChargesLabel) ?? 'One-Time Charges: ')
  const displayOtcTotalInCart = computed(() => getConfigItem(ConfigKeys.displayOtcTotalInCart) ?? false)
  const durationInLineItem = computed(() => getConfigItem(ConfigKeys.durationInLineItem) ?? false)
  const cartImage = computed(() => getConfigItem(ConfigKeys.cartImage))
  const monthlyChargesCartLabel = computed(() => {
    const macro = getConfigItem(ConfigKeys.monthlyChargesCartLabelMacro) ?? undefined
    return macro ? uiMacroParser(shopper.value, macro, true) : 'Monthly Charges: '
  })
  const expandCartLineItem = computed(() => getConfigItem(ConfigKeys.expandCartLineItem) ?? false)
  const displayMonthlyChargesWithQuantity = computed(() => getConfigItem(ConfigKeys.displayMonthlyChargesWithQuantity) ?? false)

  const monthlyChargesMultiples = computed(() => {
    if (displayMonthlyChargesWithQuantity.value) {
      const groups: any[] = []
      monthlyCharges.value.forEach((charge) => {
        const found = groups.find((group) => group.Name === charge.Name)
        if (found) {
          found.qty++
        } else {
          groups.push({ ...charge, qty: 1 })
        }
      })
      return groups.filter((group) => group.qty > 1)
    }
    return []
  })

  return {
    promoAvailable,
    orderId,
    schedule,
    shopper,
    selectedPackage,
    promo,
    monthlyCharges,
    oneTimeCharges,
    monthlyTotal,
    oneTimeTotal,
    monthlyNoFees,
    grandTotal,
    removePromo,
    phone,
    cartItems,
    itemsAndFees,
    getPrice,
    hasBadCredit,
    cartTotal,
    getAllDisclaimers,
    shoppingCart,
    downPaymentTotal,
    recurringTotal,
    quantityOfCharge,
    displayMonthlyChargesWithQuantity,
    monthlyChargesMultiples,
    cartHeaderImage,
    cartHeaderText,
    monthlyTotalText,
    cartHeaderBackgroundColor,
    sharpCorners,
    monthlyTotalColor,
    displayMonthlyPriceTopOfCart,
    showCartPackagePlanSection,
    showCartDueMonthly,
    showCartDueToday,
    cartTotalDueTodayText,
    showCartPromoToggleField,
    oneTimeChargesLabel,
    displayOtcTotalInCart,
    durationInLineItem,
    monthlyChargesCartLabel,
    expandCartLineItem,
    cartImage
  }
}
