
<div class="sub-header-container">
  <span class="sub-header-content">
    <div v-if="largeText" class="sub-header-large-text">
      {{ largeText }}
    </div>
    <v-btn class="address-button">
      <v-icon v-if="icon && punctuatedAddress">{{ icon }}</v-icon>
      <span>
        {{ punctuatedAddress }}
      </span>
    </v-btn>
    <div v-if="smallText && isTextValid(smallText.conditionals)" class="subheader-small-text">
      {{ smallText.value ?? smallText }} <br />
    </div>
  </span>
</div>
