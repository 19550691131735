<template>
  <div class="sub-header-container">
    <span class="sub-header-content">
      <div v-if="largeText" class="sub-header-large-text">
        {{ largeText }}
      </div>
      <v-btn class="address-button">
        <v-icon v-if="icon && punctuatedAddress">{{ icon }}</v-icon>
        <span>
          {{ punctuatedAddress }}
        </span>
      </v-btn>
      <div v-if="smallText && isTextValid(smallText.conditionals)" class="subheader-small-text">
        {{ smallText.value ?? smallText }} <br />
      </div>
    </span>
  </div>
</template>
<script lang="ts">
import { getPunctuatedAddressFromTags } from '@adg/catalog/src/common/addressUtil'
import { computed, defineComponent } from '@vue/composition-api'
import $store from '@/store'
import { checkConditional } from '@adg/catalog/src/common/utils'
import { Conditional } from '@adg/catalog/src/common/UIConfig'
export default defineComponent({
  name: 'SubHeader',
  components: {},
  props: {
    largeText: {
      type: String,
      default: ''
    },
    smallText: {
      type: Object || String
    },
    icon: {
      type: String,
      default: ''
    }
  },
  setup(props, { refs, root }) {
    const punctuatedAddress = computed(() => getPunctuatedAddressFromTags($store.getters.getShopper))
    const isTextValid = (conditionals?: Conditional[]) => {
      if (!conditionals || conditionals.length === 0) return true
      return checkConditional(conditionals, $store.getters.getShopper)
    }
    return { punctuatedAddress, isTextValid }
  }
})
</script>

<style></style>
