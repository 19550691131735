
<v-container fluid class="pa-0">
  <div class="addressImage">
    <v-img v-if="backgroundImage" height="100%" :src="backgroundImage"></v-img>
  </div>
  <v-row justify="center">
    <v-card flat class="addressContainer" color="transparent">
      <div
        :name="debugName('config.addressSearchText')"
        :class="messageFont"
        id="address-search-text"
        class="autocomplete-message font-weight-bold primary--text address-search-label"
        v-html="addressSearchText"
      ></div>
      <v-col v-if="addressSearchSubheaderText" id="address-search-text-subtitle" v-html="addressSearchSubheaderText"></v-col>
      <v-autocomplete
        :name="debugName('config.addressSearchPlaceholder')"
        class="pb-2"
        :color="autoCompleteColor"
        ref="autocomplete"
        v-model="model"
        :loading="loading"
        :items="addressSuggestions"
        item-text="full"
        :search-input="search"
        @update:search-input="handleSearch"
        outlined
        :placeholder="placeholder"
        solo
        @change="addressChanged"
        clearable
        clear-icon="$clear"
        :hint="addressSearchHint"
        return-object
        @click:clear="clear"
        hide-no-data
        autofocus
        no-filter
        :rules="[addressRule]"
        @blur="handleBlur"
      >
        <template v-if="submitButtonInsideInput" v-slot:append>
          <StepperButtons />
        </template>
      </v-autocomplete>
      <v-row
        v-if="serviceabilityFields || debugName('true')"
        :name="debugName('config.serviceabilityFields')"
        dense
        justify="start"
      >
        <v-container class="serviceability-fields">
          <v-col
            v-for="field in serviceabilityFields"
            :key="field.storeProp"
            :class="field.colClass ? field.colClass : 'col-12 pt-0'"
          >
            <AccountFieldUI :class="field.fieldClass" :field="field" />
          </v-col>
          <v-col v-if="!serviceabilityFields">--ServicabilityFields go here--</v-col>
        </v-container>
      </v-row>
      <v-row v-if="addressSearchSubtitle || debugName('true')" :name="debugName('config.addressSearchSubtitle')">
        <v-col v-if="addressSearchSubtitle" class="address-search-subtitle" v-html="addressSearchSubtitle"></v-col>
        <v-col v-else class="address-search-subtitle"
          ><span :name="debugName('config.addressSearchSubtitle')">--addressSearchSubtitle goes here--</span></v-col
        >
      </v-row>
      <v-row v-if="!submitButtonInsideInput" dense justify="center">
        <v-col align-self="center">
          <!-- at least 3 characters or a space, or user has previously done that to receive suggestions -->
          <StepperButtons
            :disabled="
              !(search && (search.length >= 3 || search.includes(' ') || hasUserReceivedSuggestions)) ||
              !userInputMatchesSuggestion
            "
          />
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</v-container>
