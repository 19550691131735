import { PhoneNumberDetails } from '../../common/PhoneNumber'
import { Fee, FeedItem, FeeGroup, newSchedule, Package, Product, Promo, Upgrade } from '../Catalog'
import { OrderSchedule } from '../Catalog'

type PackageWithNoProducts = Omit<Package, 'Products'>

export interface OrderPackage extends PackageWithNoProducts {
  'Product Types': string[]
  packageSubItems?: FeedItem[]
}

export const newOrderPackage = (): OrderPackage => {
  const pkg: OrderPackage = {
    Name: '',
    itemType: 'Package',
    'Product Types': []
  }
  return pkg
}

export interface ShoppingCartPhone extends Upgrade {
  phoneNumber?: string
  phoneNumberDetails?: PhoneNumberDetails
}
export interface ShoppingOrder {
  id?: number | null
  feeGroups?: string[]
  schedule?: OrderSchedule
  items: FeedItem[]
  package: OrderPackage //should this be nullable?
  products?: Product[]
  monthlyCharges: FeedItem[]
  monthlyNoFees?: FeedItem[] //does this need to be optional?
  //groupedFees?: FeeGroup[]
  //unGroupedFees?: Fee[]
  oneTimeCharges: FeedItem[]
  promo?: Promo
  installation?: string
  phone?: ShoppingCartPhone
  //groupedRecurringFees?: FeeGroup[]
  //unGroupedRecurringFees?: Fee[]
  disclaimers?: string[]
  monthlyTotal: string
  oneTimeTotal?: string
  promoProgressions?: string[]
  grandTotal: string
  lobs?: string[]
}

export const newCartState = (): ShoppingOrder => ({
  id: null,
  feeGroups: [],
  schedule: newSchedule(),
  items: [],
  package: newOrderPackage(),
  products: [],
  monthlyCharges: [],
  monthlyNoFees: [],
  oneTimeCharges: [],
  promo: undefined,
  installation: undefined,
  phone: undefined,
  disclaimers: [],
  monthlyTotal: '',
  oneTimeTotal: '',
  promoProgressions: [],
  grandTotal: '',
  lobs: []
})

export interface StoreOrder {
  id: number | null
  //cart: Cart
  cartData: ShoppingOrder
  //feeGroups: FeeGroup[]
}

export const newStoreOrder = (): StoreOrder => ({
  id: null,
  cartData: newCartState()
})
