<template>
  <div v-if="showBanner" :key="currentRoute">
    <v-system-bar app dark height="auto" :class="currentBanner?.class">
      <v-spacer></v-spacer>
      <div class="pr-10 banner-title" v-html="currentBanner?.title"></div>
      <v-spacer></v-spacer>
    </v-system-bar>
  </div>
</template>

<script lang="ts">
import { usePiniaRoute } from '@/store/pinia/piniaRoute'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { computed, defineComponent } from '@vue/composition-api'
import { getConfigItem, getConfigString } from '../shared/getConfigItem'

interface BannerConfig {
  title?: string
  class?: string
  routeDisallowMatch?: string
  routeAllowMatch?: string
  configMatch?: string
  mobile?: {
    title?: string
    class?: string
    routeDisallowMatch?: string
    routeAllowMatch?: string
  }
  hideStandardOnMobile?: boolean
}

export default defineComponent({
  name: 'Banner',
  setup(_, { root: { $vuetify } }) {
    const currentRoute = computed(() => usePiniaRoute().currentPath)

    const regexMatch = (match: string, re: string = '.*'): boolean => {
      const rex = new RegExp(re)
      return rex.test(match)
    }

    const banners = computed((): BannerConfig[] => getConfigItem(ConfigKeys.banners))
    const bannerMatch = computed(() => getConfigString(ConfigKeys.bannerMatch) ?? '') // if bannerMatch is undefined, only match '' or '.*'

    const currentBanner = computed(() => {
      if (!banners.value) return

      const isMobile = $vuetify.breakpoint.xsOnly
      const matchedBanner = banners.value
        .filter((banner) => {
          const configMatched = regexMatch(bannerMatch.value, banner.configMatch) // if configMatch is undefined, match all bannerMatch possiblities
          if (!configMatched) return false

          const routeDisallowMatch =
            isMobile && banner.mobile?.routeDisallowMatch ? banner.mobile.routeDisallowMatch : banner.routeDisallowMatch
          return routeDisallowMatch ? !regexMatch(currentRoute.value, routeDisallowMatch) : true // routeDisallow undefined, match all
        })
        .find((banner) => {
          const routeAllowMatch =
            isMobile && banner.mobile?.routeAllowMatch ? banner.mobile.routeAllowMatch : banner.routeAllowMatch
          return regexMatch(currentRoute.value, routeAllowMatch) // routeAllow undefined, match all
        })

      // Do not show banner if none matched or we're on a mobile screen, we have no mobile definition and hideStandardOnMobile is true
      if (!matchedBanner || (isMobile && !matchedBanner.mobile && matchedBanner.hideStandardOnMobile)) {
        return
      }

      // Use mobile properties if we're on a mobile screen and always fallback to standard properties
      const retVal: BannerConfig = {
        title: (isMobile && matchedBanner.mobile?.title) || matchedBanner.title,
        class: (isMobile && matchedBanner.mobile?.class) || matchedBanner.class || 'default-banner primary'
      }

      return retVal
    })

    const showBanner = computed(() => currentBanner.value?.title)

    return {
      currentRoute,
      showBanner,
      currentBanner
    }
  }
})
</script>

<style>
.default-banner {
  text-align: center !important;
}
</style>
