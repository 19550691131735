import { FullAddress } from '../modules/Address'
import { IShopper } from '../modules/Shopper/v2/shopper'
import * as SmartyStreetsSDK from 'smartystreets-javascript-sdk'

export interface SmartyStreetsLookupSuggestions {
  street_line: string
  secondary: string
  city: string
  state: string
  zipcode: string
  entries: number
  source?: string
}

export interface OurAddressExtensions {
  full?: string
  formattedAddress?: string
  punctuatedAddress?: string
  secondarySearch?: string
}

export type Place = Partial<SmartyStreetsLookupSuggestions> & OurAddressExtensions

export interface SmartyStreetsLookupResponse {
  suggestions: SmartyStreetsLookupSuggestions[]
}

export const placeFromMatchedAddress = (matchedAddress: FullAddress): Place => {
  const place: Place = {
    full: matchedAddress.inputAddress,
    street_line: formatAddressLine1(matchedAddress),
    secondary: matchedAddress.addressLine2 ? matchedAddress.addressLine2 : '',
    city: matchedAddress.city,
    state: matchedAddress.state,
    zipcode: matchedAddress.zip
  }
  return place
}

export const formatAddressLine1 = (ma: FullAddress): string => {
  const sl = `${ma.streetNum ?? ''} ${ma.prefix ?? ''} ${ma.street ?? ''} ${ma.streetType ?? ''} ${ma.suffix ?? ''}`
    .replace(/\s+/g, ' ')
    .trim()
  return sl
}

// export function formatAddressLine1(obj: FullAddress): string {
//   const addressLine1Parts: (keyof FullAddress)[] = ['streetNum', 'prefix', 'street', 'streetType', 'suffix']
//   return formatAddressUsingParts(obj, addressLine1Parts)
// }

export function formatAddressLine1FromComponents(obj: SmartyStreetsSDK.usStreet.Component): string {
  const addressLine1Parts: (keyof SmartyStreetsSDK.usStreet.Component)[] = [
    'primaryNumber',
    'streetPredirection',
    'streetName',
    'streetSuffix',
    'streetPostdirection'
  ]
  return formatAddressUsingParts(obj, addressLine1Parts)
}

export function formatAddressLine2FromComponents(obj: SmartyStreetsSDK.usStreet.Component): string {
  const addressLine2Parts: (keyof SmartyStreetsSDK.usStreet.Component)[] = ['secondaryDesignator', 'secondaryNumber']
  return formatAddressUsingParts(obj, addressLine2Parts)
}

function formatAddressUsingParts(obj: any, parts: string[]): string {
  const addressLine: any[] = []

  parts.forEach((part: any) => {
    const foundTag = Object.entries(obj).find((tag) => tag[0] === part)
    if (foundTag && foundTag[1]) addressLine.push(foundTag[1])
  })
  return addressLine.length > 1 ? addressLine.join(' ').trim() : addressLine.length === 1 ? addressLine[0] : ''
}

export function getInputAddressFromTags(shopper: IShopper): string | undefined {
  return (shopper.tags?.inputAddress as string) ?? undefined
}

export function getPunctuatedAddressFromTags(shopper: IShopper): string {
  if (shopper.tags) {
    const addr = shopper.tags
    if (addr.addressLine1 && addr.city && addr.state && addr.zip) {
      return (
        `${addr.addressLine1},` + (addr.addressLine2 ? ` ${addr.addressLine2},` : '') + ` ${addr.city}, ${addr.state} ${addr.zip}`
      )
    }
  }
  return ''
}

// These regexes allow us to detect and replace and disallowed character in the input.
// These are duplicated in apps/address-search/src/HelperFunctions.ts for same logic in AddressSearchComponent.
export class AddressMask {
    private static disallowedChars: string[] = ['@']
    static disallowedCharsRegex = new RegExp(`^[^${this.disallowedChars.join()}]*$`)
    static replaceRegex = new RegExp(`[${this.disallowedChars.join()}]`, 'g')

    static hasDisallowedChars(input: string): boolean {
        return !this.disallowedCharsRegex.test(input)
    }

    static removeDisallowedChars(input: string): string {
        return input.replace(this.replaceRegex, '')
    }
}

