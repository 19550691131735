import { bus } from '@/main'
import { SET_PACKAGE, SET_UNIQUE_PLAN_ID } from '@/store/types'
import {
  ConfigKeys,
  Equipment,
  Feature,
  FeaturePackagePiece,
  FeaturePackagePieceItem,
  getItemPrice,
  getItemPriceAsNumber,
  itemDisplayName,
  Upgrade
} from '@adg/catalog/src/modules/Catalog'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { Package } from '@adg/catalog/src/modules/Catalog'
import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api'
import useUiConfig from '@/components/shared/useUiConfig'
import { PackagePiece } from '@adg/catalog/src/common/UIConfig'
import useTempCart from '@/components/order/cart/useTempCart'
import { logger } from '@/utils/RemoteLogger'
import { newCart } from '@adg/catalog/src/modules/Order'
import useCart from '../cart/useCart'

export default ($store, $vuetify, emit) => {
  const { offerHeaderColors, offerButtonColors } = useUiConfig($store)

  const selectPackage = (pkg: Package) => {
    $store.commit('setCart', buildCart(pkg))
    // $store.commit(SET_PACKAGE, pkg)
    $store.commit(SET_UNIQUE_PLAN_ID, pkg.uniquePlanId)
    emit('packageSelected')
    bus.$emit('packageChanged')
  }

  const underPriceId = (filterPackagesCount: number): string => {
    return filterPackagesCount === 5 && $vuetify.breakpoint.md ? 'under-price-column' : ''
  }

  const image = (packageData: Package): string => {
    return 'api/content/' + (packageData?.Image ?? 'package-header.png')
  }

  const imageToUse = (packageData: Package): string => {
    if (getConfigItem(ConfigKeys.singlePackageHeader)) {
      return 'api/content/' + getConfigItem(ConfigKeys.singlePackageHeader)
    }
    if (packageData?.lobs) {
      const lobs = JSON.parse(JSON.stringify(packageData.lobs))
      lobs.sort((a, b) => (a > b ? 1 : -1))
      const retVal = lobs?.join('').replace(/\s/g, '') + '.png'
      return 'api/content/' + retVal
    }
    return ''
  }

  const offerCardId = (packageData: Package, colorIndex: number): string => {
    const lobString = packageData?.lobs?.join('')
    if (lobString) {
      return lobString + (colorIndex + 1)
    }
    return ''
  }

  const numberOfProducts = (packageData: Package): number => {
    return new Set(packageData?.Products.map((p) => p['Product Type'])).size
  }

  const packageNameHeight = (packageData: Package): number => {
    return numberOfProducts(packageData) < 3 ? 80 : 110
  }

  const packageMaxWidthOld = (filterPackagesCount: number): string => {
    return filterPackagesCount === 5 && $vuetify.breakpoint.md ? '230' : '340'
  }

  const packageMaxWidth = (filterPackagesCount: number = 1) => {
    const maxWidth = ($vuetify.breakpoint.width * 0.9) / filterPackagesCount

    if ($vuetify.breakpoint.mdAndUp) {
      return maxWidth > 340 ? 340 : maxWidth
    } else return maxWidth < 225 ? 225 : maxWidth
  }

  const priceParts = (packageData: Package): string[] => {
    const alacarteMonthlyPrice = packageData.packagePriceType === 'alacarteMonthlyPrice'
    const cart = computed(() => buildCart(packageData, alacarteMonthlyPrice))
    const { monthlyTotal } = useCart($store, cart)
    const packageTotal = getItemPriceAsNumber(packageData, 'Monthly Price')
    const useTrueMonthlyPrice = packageData.packagePriceType === 'trueMonthlyPrice'
    const rawPrice = useTrueMonthlyPrice || alacarteMonthlyPrice ? monthlyTotal.value : packageTotal
    const price = rawPrice.toFixed(2).split('.') ?? ['0']
    if (price.length === 1) {
      price.push('00')
    }
    return price
  }

  const speedUnits = (packageData: Package): string => {
    return packageData?.Products?.find((p) => p['Product Type'] === 'Internet')?.['Speed Unit'] ?? ''
  }

  const speed = (packageData: Package): number | string => {
    return packageData?.Products?.find((p) => p['Product Type'] === 'Internet')?.Speed ?? ''
  }

  const displayName = (packageData: Package): string => {
    if (getConfigItem(ConfigKeys.packageSquareUsePackageDescription) && packageData.Description) {
      return packageData.Description
    }
    const product = packageData?.Products?.find((p) => p['Product Type'] === 'Internet')
    return product !== undefined ? itemDisplayName(product) : itemDisplayName(packageData)
  }

  const internetProductDisplayName = (packageData: Package): string => {
    const product = packageData?.Products?.find((p) => p['Product Type'] === 'Internet')
    return product !== undefined ? itemDisplayName(product) : itemDisplayName(packageData)
  }

  const duration = (packageData: Package): string => {
    if (packageData['Offer Duration']) {
      return packageData['Offer Duration']
    } else {
      return getConfigItem(ConfigKeys.costDuration) ?? 'mo./1yr'
    }
  }

  const mktTextLines = (packageData: Package): number => {
    return Object.keys(packageData).filter((k) => k.startsWith('Marketing Text')).length ?? []
  }

  const backgroundImage = (packageData: Package): string | undefined => {
    // return 'api/content/noimg.png' //this is not working correctly and needs to be fixed

    return packageData?.backgroundImage ? `api/content/${packageData?.backgroundImage}` : undefined
  }

  const backgroundColor = (
    colorIndex: number | undefined,
    offerHeaderColors: string[],
    offerHeaderSecondaryColors: string[]
  ): string => {
    const primary = colorIndex !== undefined ? offerHeaderColors[colorIndex % offerHeaderColors.length] : 'white'
    const secondary =
      colorIndex !== undefined && offerHeaderSecondaryColors.length
        ? offerHeaderSecondaryColors[colorIndex % offerHeaderSecondaryColors.length]
        : undefined
    return secondary ? `-webkit-linear-gradient(${secondary}, ${primary})` : primary
  }

  const showUnderPriceText = computed(() => getConfigItem(ConfigKeys.showUnderPriceText) ?? false)

  const marketingTextArray = (packageData: Package): Array<any> => {
    return [
      packageData?.['Marketing Text 1'],
      packageData?.['Marketing Text 2'],
      packageData?.['Marketing Text 3'],
      packageData?.['Marketing Text 4'],
      packageData?.['Marketing Text 5'],
      packageData?.['Marketing Text 6'],
      packageData?.['Marketing Text 7'],
      packageData?.['Marketing Text 8']
    ]
  }

  const hrWidth = (filterPackagesCount: number): string => {
    if (packageMaxWidth(filterPackagesCount) === '340') {
      return '270'
    } else return '170'
  }

  const underPriceTextHeight = (filterPackagesCount: number): string => {
    if (packageMaxWidth(filterPackagesCount) === '230') {
      return '81px'
    } else return '65px'
  }

  const packageCardSquareCorners = computed(() => getConfigItem(ConfigKeys.packageCardSquareCorners) ?? false)

  const packageCardClass = computed(() => {
    let packageSquareCorners = packageCardSquareCorners
    return packageSquareCorners.value === true ? 'mx-auto lg tr-0 tl-0' : 'mx-auto rounded-lg rounded-tr-0 rounded-tl-0'
  })

  const headerText = (headerTextType: string, packageData: Package): string => {
    let headerText: string = ''

    if (headerTextType === 'speed') {
      headerText = speed(packageData).toString() + ' ' + speedUnits(packageData)
    }
    if (headerTextType === 'description') {
      headerText = packageDescription(packageData)
    }
    if (headerTextType === 'displayName') {
      headerText = packageDisplayName(packageData)
    }
    if (headerTextType === 'priceWithCents') {
      headerText = '$' + priceParts(packageData)[0] + '.' + priceParts(packageData)[1] + duration(packageData)
    }
    if (headerTextType === 'priceWithoutCents') {
      headerText = '$' + priceParts(packageData)[0] + duration(packageData)
    }

    return headerText
  }

  const approvedBroadbandLabelClasses = computed(() => getConfigItem(ConfigKeys.approvedBroadbandLabelClasses) ?? [])
  const broadbandConfigString = computed(() => getConfigItem(ConfigKeys.broadbandConfigString) ?? '')
  const broadbandLabelPlacement = computed(() => {
    const placement = getConfigItem(ConfigKeys.broadbandLabelPlacement)
    if (placement === 'expandable-footer' || placement === 'flippable-footer') {
      return 'footer'
    }
    return placement
  })

  const displayNameTextColor = (i) => {
    if (i % 4 === 0) {
      return getConfigItem(ConfigKeys.firstDisplayNameColor) ?? ''
    }
    if (i % 4 === 1) {
      return getConfigItem(ConfigKeys.secondDisplayNameColor) ?? ''
    }
    if (i % 4 === 2) {
      return getConfigItem(ConfigKeys.thirdDisplayNameColor) ?? ''
    }
    if (i % 4 === 3) {
      return getConfigItem(ConfigKeys.fourthDisplayNameColor) ?? ''
    }
  }

  const abovePriceText = computed(() => getConfigItem(ConfigKeys.abovePriceText))

  const noBullets = computed(() => getConfigItem(ConfigKeys.noBullets) ?? false)

  const hidePackagePriceZeroes = computed(() => getConfigItem(ConfigKeys.hidePackagePriceZeroes) ?? false)

  const offerButtonColor = computed(() => getConfigItem(ConfigKeys.offerButtonColor))

  const offerButtonText = computed(() => getConfigItem(ConfigKeys.offerButtonText) ?? 'Select')

  const packageNameInSelectButton = computed(() => getConfigItem(ConfigKeys.packageNameInSelectButton) ?? false)

  const showSpeedUnits = computed(() => getConfigItem(ConfigKeys.showSpeedUnits) ?? true)

  const marketingTextIconColor = computed(() => getConfigItem(ConfigKeys.marketingTextIconColor) ?? 'black')

  const showDisplayName = computed(() => getConfigItem(ConfigKeys.showDisplayName) ?? false)

  const showDescription = computed(() => getConfigItem(ConfigKeys.showDescription) ?? false)

  const showUpperOfferButton = computed(() => getConfigItem(ConfigKeys.showUpperOfferButton) ?? false)

  const showRoundedOfferButton = computed(() => getConfigItem(ConfigKeys.showRoundedOfferButton) ?? false)

  const showTextAbovePrice = computed(() => getConfigItem(ConfigKeys.showTextAbovePrice) ?? false)

  const isOfferTileClickable = computed(() => getConfigItem(ConfigKeys.isOfferTileClickable) ?? false)

  const packagePieces = computed(() => getConfigItem(ConfigKeys.packagePieces))

  const closeLabelIconText = computed(() => getConfigItem(ConfigKeys.closeLabelIconText) ?? undefined)

  const closeLabelIcon = computed(() => {
    return getConfigItem(ConfigKeys.closeLabelIcon) ?? 'mdi-arrow-u-left-top-bold'
  })

  const closeLabelIconColor = computed(() => getConfigItem(ConfigKeys.closeLabelIconColor) ?? 'red')

  const broadbandLabelToggleOption = computed(() => getConfigItem(ConfigKeys.broadbandLabelToggleOption) ?? 'expand')

  const packageDescription = (packageData: Package) => {
    if (getConfigItem(ConfigKeys.useDisplayNameInPackageHeader)) {
      return itemDisplayName(packageData)
    } else return packageData.Description
  }

  const displayFeatures = (packageData: Package, packageFeaturePiece: FeaturePackagePiece) => {
    // checks if there is any feature associated to the package with the same name as the parent feature name of any of the packageFeaturePiece items
    const parentFeatureNames = packageFeaturePiece.packageFeatureItems.map((featurePieceItem: FeaturePackagePieceItem) => {
      return featurePieceItem.parentName
    })
    if (packageData.Features?.find((feature: Feature) => parentFeatureNames.includes(feature.Name))) {
      return true
    } else {
      return false
    }
  }

  const getPackageFeatureText = (
    packageData: Package,
    parentTextFeatureName: string,
    childTextFeatureName: string
  ): string | undefined => {
    const parentPackageTextFeature = packageData.Features?.find((feature: Feature) => feature.Name === parentTextFeatureName)
    const chileTextFeature = parentPackageTextFeature?.Features?.find((feature: Feature) => feature.Name === childTextFeatureName)

    return chileTextFeature?.Description ?? undefined
  }

  const featureImage = (
    packageData: Package,
    parentImageFeatureName: string,
    childImageFeatureName: string
  ): string | undefined => {
    const parentPackageImageFeature = packageData.Features?.find((feature: Feature) => feature.Name === parentImageFeatureName)
    const childImageFeature = parentPackageImageFeature?.Features?.find(
      (feature: Feature) => feature.Name === childImageFeatureName
    )
    return 'api/content/' + childImageFeature?.Image ?? undefined
  }

  const packageDisplayName = (packageData: Package) => {
    if (getConfigItem(ConfigKeys.useDisplayNameInPackageHeader)) {
      return packageData.Description
    } else return itemDisplayName(packageData)
  }

  const getColorHeaderClass = (packageData: Package) => {
    let lobString = ''
    if (packageData.lobs) {
      packageData.lobs
        .slice()
        .sort()
        .forEach((lob) => {
          if (lob) {
            lobString += '-' + lob.toLowerCase()
          }
        })
    }
    return 'color-header' + lobString ?? ''
  }

  const itemClass = (item: PackagePiece, numLines: number) => {
    if (item.largeClass && numLines >= 3) {
      return item.largeClass
    }
    if (item.medClass && numLines === 2) {
      return item.medClass
    } else return item.class
  }

  const getOfferButtonColor = (item: PackagePiece, colorIndex: number) => {
    if (item.singleButtonColor) return item.singleButtonColor
    else {
      return item.buttonSameColorAsCard
        ? offerHeaderColors.value[colorIndex % offerHeaderColors.value.length]
        : offerButtonColors.value[colorIndex % offerButtonColors.value.length]
    }
  }

  //todo: revisit
  const getPackageMarketingTextAtIndex = (packageData: Package, index: number): string => {
    const marketingText = packageData[('Marketing Text ' + index.toString()) as keyof Package]
    return typeof marketingText === 'string' ? marketingText : ''
  }

  const buildCart = (pkg: Package, alacarteMonthlyPrice?: boolean) => {
    // cart 1,2,3 (decoupled from shopper)
    //

    try {
      const tempCart = newCart()
      // state.shopper.cart.tv.upgrades = []

      // const origPkg = { ...state.shopper.cart.package }
      // ecom.pushPackageChange(origPkg ? { ...origPkg } : null, { ...pkg })
      // ga4.pushPackageChange(origPkg ? { ...origPkg } : null, { ...pkg })
      // this.commit('resetTempCart', cartNumber)
      // this.commit('resetTempCart', cartNumber)
      tempCart.package = pkg
      tempCart.products = pkg.Products.map((p) => ({ ...p }))
      const packageServices =
        pkg.Upgrades?.filter((u) => u.Subcategory === 'Package Service' || u.Subcategory === 'Streaming Service') ?? []
      if (packageServices) {
        let defaultUpgrades = packageServices.filter((u: any) => u.Default === 'yes' && !u.required && !u.included && !u.excluded)
        const excludedUpgrades = packageServices.filter((u) => {
          return u.excluded
        })
        const requiredUpgrades = packageServices.filter((u: any) => u.required && !u.excluded)
        const includedUpgrades = packageServices.filter((u) => u.included === '1' && u.excluded !== '1')
        const alacarteUpgrades = packageServices.filter((u: Upgrade) => u.alacarte)
        // .map(
        //   (u) =>
        //     ({
        //       ...u,
        //       'Monthly Price': 'Included'
        //     } as Upgrade)
        // )
        let upgrades: Upgrade[] = []
        if (alacarteMonthlyPrice) {
          upgrades = alacarteUpgrades
        } else {
          upgrades = upgrades.filter((upgrade) => packageServices?.find((e) => e.Name === upgrade.Name))
          if (includedUpgrades.length) {
            upgrades = upgrades.filter((upgrade) => {
              if (includedUpgrades.find((e) => e.Name === upgrade.Name)) return false
              if (includedUpgrades.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
              return true
            })

            upgrades = [...upgrades, ...includedUpgrades]
          }
          if (requiredUpgrades.length) {
            upgrades = upgrades.filter((upgrade) => {
              if (requiredUpgrades.find((e) => e.Name === upgrade.Name)) return false
              if (requiredUpgrades.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
              return true
            })
            upgrades = [...upgrades, ...requiredUpgrades]
          }
          if (defaultUpgrades.length) {
            upgrades = upgrades.filter((upgrade) => !defaultUpgrades.find((e) => e.Name === upgrade.Name))
            //in the case of groups with defaults, we want to ignore the defaults when there are already upgrades selected
            defaultUpgrades = defaultUpgrades.filter((df) => !upgrades.find((up) => up.Group === df.Group && up.Min === 1))
            upgrades = [...upgrades, ...defaultUpgrades]
          }
          if (excludedUpgrades.length) {
            upgrades = upgrades.filter((upgrade) => !excludedUpgrades.find((e) => e.Name === upgrade.Name))
          }
        }
        //state.shopper.cart.packageUpgrades = upgrades
        //hopefully fix ga4 package upgrades
        tempCart.packageUpgrades = upgrades
        // this.commit('setTempPackageUpgrades', {
        //   upgrade: {
        //     upgrades: upgrades,
        //     subCategory: 'Package Service'
        //   },
        //   cartNumber: cartNumber
        // })
      }
      // state.shopper.cart.packageUpgrades = []
      // state.availablePackageUpgrades = pkg.Upgrades || []

      //TODO: handle pkg.Upgrades for excluded, reguired and included

      const tv = pkg.Products.find((p) => p['Product Type'] === 'TV')
      if (tv) {
        const upgradesAndEquipment = (tv.Equipment ?? []).concat(tv.Upgrades ?? [])

        let defaultEquipment =
          upgradesAndEquipment?.filter(
            (u) => u.Default === 'yes' && u.Max === 1 && u.required !== '1' && u.included !== '1' && u.excluded !== '1'
          ) || []
        const tvRequiredEquipment = upgradesAndEquipment?.filter((u) => u.required === '1' && u.excluded !== '1')
        const tvIncludedEquipment = upgradesAndEquipment?.filter((u) => u.included === '1' && u.excluded !== '1')
        const alacarteEquipment = upgradesAndEquipment.filter((u) => u.alacarte)
        // .map(
        //   (u) =>
        //     ({
        //       ...u,
        //       'Monthly Price': 'Included'
        //     } as Equipment)
        // )
        const tvExcludedEquipment = upgradesAndEquipment?.filter((u) => u.excluded === '1')

        let upgrades: (Equipment | Upgrade)[] = []
        if (alacarteMonthlyPrice) {
          upgrades = alacarteEquipment
        } else {
          // remove equipment that doesn't exist on new package
          upgrades = upgrades.filter((upgrade) => upgradesAndEquipment?.find((e) => e.Name === upgrade.Name))

          if (tvIncludedEquipment.length) {
            //filter duplicate equipment
            upgrades = upgrades.filter((upgrade) => {
              if (tvIncludedEquipment.find((e) => e.Name === upgrade.Name)) return false
              if (tvIncludedEquipment.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
              return true
            })

            upgrades = [...upgrades, ...tvIncludedEquipment]
          }
          if (tvRequiredEquipment.length) {
            //filter duplicate requireds
            upgrades = upgrades.filter((upgrade) => {
              if (tvRequiredEquipment.find((e) => e.Name === upgrade.Name)) return false
              if (tvRequiredEquipment.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
              return true
            })
            upgrades = [...upgrades, ...tvRequiredEquipment]
          }
          if (defaultEquipment.length) {
            //filter duplicate defaults
            upgrades = upgrades.filter((upgrade) => !defaultEquipment.find((e) => e.Name === upgrade.Name))
            //in the case of groups with defaults, we want to ignore the defaults when there are already upgrades selected
            defaultEquipment = defaultEquipment.filter((df) => !upgrades.find((up) => up.Group === df.Group && up.Min === 1))
            upgrades = [...upgrades, ...defaultEquipment]
          }
          if (tvExcludedEquipment.length) {
            upgrades = upgrades.filter((upgrade) => !tvExcludedEquipment.find((e) => e.Name === upgrade.Name))
          }
        }
        // state.shopper.cart.tv.upgrades = upgrades
        // hopefully fix ga4 bug
        tempCart.tv.upgrades = upgrades
        // this.commit('setTempTvUpgrades', { upgrades, cartNumber })

        // const included =
        //   tv.Upgrades?.filter((u) => u.included === '1').map(
        //     (u) =>
        //       ({
        //         ...u,
        //         'Monthly Price': 'Included'
        //       } as Upgrade)
        //   ) || []

        // state.shopper.cart.tv.numTv = 0
        // // let upgrades: Equipment[] = state.shopper.cart.tv.upgrades
        // // upgrades = upgrades.filter((upgrade) => tv.Upgrades?.find((e) => e.Name === upgrade.Name))

        // // if (included) {
        // //   upgrades = upgrades.filter((upgrade) => !included.find((e) => e.Name === upgrade.Name))
        // //   upgrades = [...upgrades, ...included]
        // // }

        // state.shopper.cart.tv.upgrades = included
      } else {
        tempCart.tv.upgrades = []
        tempCart.tv.equipment = []
        tempCart.tv.numTv = 0
      }
      const internet = pkg.Products.find((p) => p['Product Type'] === 'Internet')
      if (internet) {
        const upgradesAndEquipment = (internet.Equipment ?? []).concat(internet.Upgrades ?? [])

        let defaultEquipment =
          upgradesAndEquipment?.filter(
            (u) => u.Default === 'yes' && u.required !== '1' && u.included !== '1' && u.excluded !== '1'
          ) || []
        const internetRequired = upgradesAndEquipment?.filter((u) => u.required === '1' && u.excluded !== '1')
        const internetIncluded = upgradesAndEquipment?.filter((u) => u.included === '1' && u.excluded !== '1')
        const alacarteEquipment = upgradesAndEquipment.filter((u) => u.alacarte)
        // this map section should not be needed anymore
        // .map(
        //   (u) =>
        //     ({
        //       ...u,
        //       'Monthly Price': 'Included'
        //     } as Equipment)
        // )
        const internetExcluded = upgradesAndEquipment?.filter((u) => u.excluded === '1')

        let upgrades: Equipment[] = []
        if (alacarteMonthlyPrice) {
          upgrades = alacarteEquipment
        } else {
          // remove equipment that doesn't exist on new package
          upgrades = upgrades.filter((upgrade) => upgradesAndEquipment?.find((e) => e.Name === upgrade.Name))

          if (internetIncluded.length) {
            //filter duplicate equipment
            upgrades = upgrades.filter((upgrade) => {
              if (internetIncluded.find((e) => e.Name === upgrade.Name)) return false
              if (internetIncluded.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
              return true
            })

            upgrades = [...upgrades, ...internetIncluded]
          }
          if (internetRequired.length) {
            //filter duplicate requireds
            upgrades = upgrades.filter((upgrade) => {
              if (internetRequired.find((e) => e.Name === upgrade.Name)) return false
              if (internetRequired.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
              return true
            })
            upgrades = [...upgrades, ...internetRequired]
          }
          if (defaultEquipment.length) {
            //filter duplicate defaults
            upgrades = upgrades.filter((upgrade) => !defaultEquipment.find((e) => e.Name === upgrade.Name))
            //in the case of groups with defaults, we want to ignore the defaults when there are already upgrades selected
            defaultEquipment = defaultEquipment.filter(
              (df) => !upgrades.find((up) => up.Group === df.Group && up.Min === 1 && df.Min === 1)
            )
            upgrades = [...upgrades, ...defaultEquipment]
          }
          if (internetExcluded.length) {
            upgrades = upgrades.filter((upgrade) => !internetExcluded.find((e) => e.Name === upgrade.Name))
          }
        }
        // state.shopper.cart.internet.upgrades = upgrades
        // hopefully fix ga4 events
        tempCart.internet.upgrades = upgrades
        // this.commit('setTempInternetUpgrades', { upgrades, cartNumber })
      }

      const phone = pkg.Products.find((p) => p['Product Type'] === 'Phone')
      if (phone) {
        const upgradesAndEquipment = (phone.Equipment ?? []).concat(phone.Upgrades ?? [])

        let defaultEquipment =
          upgradesAndEquipment?.filter(
            (u) => u.Default === 'yes' && u.required !== '1' && u.included !== '1' && u.excluded !== '1'
          ) || []
        const phoneRequired = upgradesAndEquipment?.filter((u) => u.required === '1' && u.excluded !== '1')
        const phoneIncluded = upgradesAndEquipment
          ?.filter((u) => u.included === '1' && u.excluded !== '1')
          .map(
            (u) =>
              ({
                ...u,
                'Monthly Price': 'Included'
              } as Equipment)
          )
        const alacarteEquipment = upgradesAndEquipment.filter((u) => u.alacarte)
        let upgrades: Equipment[] = []

        if (alacarteMonthlyPrice) {
          upgrades = alacarteEquipment
        } else {
          upgrades = upgrades.filter((upgrade) => upgradesAndEquipment?.find((e) => e.Name === upgrade.Name))
          if (phoneIncluded.length) {
            upgrades = upgrades.filter((upgrade) => {
              if (phoneIncluded.find((e) => e.Name === upgrade.Name)) return false
              if (phoneIncluded.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
              return true
            })

            upgrades = [...upgrades, ...phoneIncluded]
          }
          if (phoneRequired.length) {
            upgrades = upgrades.filter((upgrade) => {
              if (phoneRequired.find((e) => e.Name === upgrade.Name)) return false
              if (phoneRequired.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
              return true
            })
            upgrades = [...upgrades, ...phoneRequired]
          }
          if (defaultEquipment.length) {
            upgrades = upgrades.filter((upgrade) => !defaultEquipment.find((e) => e.Name === upgrade.Name))
            defaultEquipment = defaultEquipment.filter((df) => !upgrades.find((up) => up.Group === df.Group && up.Min === 1))

            upgrades = [...upgrades, ...defaultEquipment]
          }
        }

        // state.shopper.cart.phone.equipment = upgrades
        // hopefully fix ga4 events
        tempCart.phone.equipment = upgrades
        // this.commit('setTempPhoneEquipment', { upgrades, cartNumber })
      }
      // state.tempCart[cartNumber].promo = undefined
      return tempCart
    } catch (e) {
      logger.error('Error updating package', e)
      return newCart()
    }
  }

  return {
    hidePackagePriceZeroes,
    packageCardSquareCorners,
    packageCardClass,
    offerButtonText,
    abovePriceText,
    noBullets,
    offerButtonColor,
    showSpeedUnits,
    marketingTextIconColor,
    showDisplayName,
    showDescription,
    showUnderPriceText,
    showUpperOfferButton,
    showRoundedOfferButton,
    showTextAbovePrice,
    isOfferTileClickable,
    packagePieces,
    offerHeaderColors,
    offerButtonColors,
    broadbandConfigString,
    approvedBroadbandLabelClasses,
    broadbandLabelPlacement,
    closeLabelIcon,
    closeLabelIconColor,
    closeLabelIconText,
    broadbandLabelToggleOption,
    offerCardId,
    numberOfProducts,
    packageNameHeight,
    image,
    imageToUse,
    priceParts,
    speedUnits,
    displayName,
    packageMaxWidth,
    duration,
    underPriceId,
    selectPackage,
    mktTextLines,
    backgroundImage,
    backgroundColor,
    marketingTextArray,
    hrWidth,
    underPriceTextHeight,
    packageDescription,
    packageDisplayName,
    headerText,
    speed,
    internetProductDisplayName,
    getColorHeaderClass,
    itemClass,
    getOfferButtonColor,
    getPackageMarketingTextAtIndex,
    displayFeatures,
    getPackageFeatureText,
    featureImage,
    displayNameTextColor,
    packageNameInSelectButton,
    buildCart
  }
}
