import { flatten, get, trim } from 'lodash'
import { IShopper } from '../modules/Shopper'
import { Conditional } from './UIConfig'
import { ItemType } from '../modules/Catalog/v4/interface/Catalog'

interface DateDiff {
  years: number
  months: number
  days: number
}

//export type UrlParams = Record<string, string | string[]>

export type UrlParamValue = string | string[]

export interface UrlParams {
  token?: UrlParamValue
  order?: UrlParamValue
  address?: UrlParamValue
  debug?: UrlParamValue
  type?: UrlParamValue
  [key: string]: UrlParamValue | undefined
}

export interface MakeGetArgConfig {
  name: string
  src: string
  element: string
  defaultVal?: any
  type?: string
  trueVal?: any
  falseVal?: any
  matchVal?: string
  hideIfUndefined?: boolean
  section?: string
  [key: string]: any
}

function isAklamioId(val: any): val is string {
  return typeof val === 'string' && val.startsWith('ar') && val.length === 66
}

export const makeGetArgGlobal = (config: MakeGetArgConfig, shp: IShopper, tkt?: any) => {
  //todo: fix any
  const { name, src, element, defaultVal, type, trueVal, falseVal, matchVal } = config
  if (!tkt && src === 'TICKET') {
    throw new Error('Ticket is required')
  }
  let urlArg
  try {
    switch (src) {
      case 'ADDRESS':
        urlArg = getDescendantProp(shp, `address.${element}`)
        break
      case 'SHOPPER':
        urlArg = getDescendantProp(shp, `${element}`)
        break
      case 'TICKET':
        urlArg = getDescendantProp(tkt, `${element}`)
        break
      case 'ORDER':
        urlArg = getDescendantProp(tkt, `order.${element}`)
        break
      default:
        urlArg = ''
    }
  } catch (e: any) {
    urlArg = e
      .toString()
      .replace(/^.*(?=Cannot)/, '')
      .replace(' of undefined', '')
  }
  if (!urlArg || urlArg === '') {
    urlArg = defaultVal
  }

  if (urlArg && element === 'referralId?' && isAklamioId(urlArg)) {
    urlArg = `${urlArg.slice(0, 2)} - ${urlArg.slice(2, 34)} - ${urlArg.slice(34)}`
  }

  return urlArg
  function getDescendantProp(obj: any, desc: any) {
    const arr = desc.split('.')
    while (arr.length > 0) {
      let hasQuestionMark = false
      if (arr[0].match(/\?$/)) {
        arr[0] = arr[0].replace(/\?$/, '')
        hasQuestionMark = true
      }
      const elem = arr.shift()
      obj = obj[elem]
      if (obj === undefined || obj === null) {
        if (hasQuestionMark) {
          return undefined
        } else {
          throw new Error(`Cannot read property ${elem}`)
        }
      }
    }
    if (type === 'isoDate' && obj) {
      obj = new Date(obj).toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })
    }
    if (type === 'isoDateTime' && obj) {
      obj = new Date(obj).toLocaleString('en-US')
    }

    if (type === 'money' && obj) {
      obj = `$${obj}`
    }

    if (type === 'boolean') {
      let isTrue = false
      if (matchVal) {
        isTrue = obj === matchVal
      } else {
        isTrue = obj
      }
      if (isTrue && trueVal) {
        obj = trueVal
      }
      if (!isTrue) {
        obj = falseVal || undefined
      }
    }
    if (typeof obj === 'number') {
      obj = `${obj}`
    }

    return obj
  }
}

export const getFirstUrlParam = (param?: UrlParamValue): string | undefined => {
  if (Array.isArray(param)) {
    return param[0]
  }
  return param
}

export const getNum = (n: number | string): number => {
  return typeof n === 'string' ? parseInt(n) : n
}

export const dateDiff = (startingDate: Date, endingDate?: Date): DateDiff => {
  if (!endingDate) {
    endingDate = new Date()
  }
  let startDate = new Date(new Date(startingDate).toISOString().substr(0, 10))
  let endDate = new Date(new Date(endingDate).toISOString().substr(0, 10)) // need date in YYYY-MM-DD format

  // if (startDate > endDate) {
  //   let swap = startDate
  //   startDate = endDate
  //   endDate = swap
  // }
  let startYear = startDate.getFullYear()
  let february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28
  let daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  let years = endDate.getFullYear() - startYear
  let months = endDate.getMonth() - startDate.getMonth()
  if (months < 0) {
    years--
    months += 12
  }
  let days = endDate.getDate() - startDate.getDate()
  if (days < 0) {
    if (months > 0) {
      months--
    } else {
      years--
      months = 11
    }
    days += daysInMonth[startDate.getMonth()]
  }

  return { years, months, days }
}

export const conditionReasons = (conditions: (Conditional | Conditional[])[]) =>
  flatten(conditions)
    .map((c) => {
      return `shopper.${c.element}${c.field ? '.' + c.field : ''} = ${c.value ?? ''} ${c.matchType ?? ''} ${c.regexValue ?? ''}`
    })
    .join(', ')

export const anyConditionTrue = (customBlockerConditions: (Conditional | Conditional[])[], shopper: IShopper): boolean =>
  getTrueConditions(customBlockerConditions, shopper).length > 0

export const getTrueConditions = (
  customBlockerConditions: (Conditional | Conditional[])[],
  shopper: IShopper
): (Conditional | Conditional[])[] => {
  let matchedConditions: (Conditional | Conditional[])[] = []
  customBlockerConditions.forEach((blockerCondition) => {
    //if multiple conditions grouped together, check each one. otherwise check the single condition.
    //if a single condition or group of conditions is true, block automation

    //examples:
    // [false, true, false] => true
    // [false, [true, false], false] => false
    // [false, [true, false], true] => true
    // [false, [true, true], false] => true

    // [[true, true, false]] => false
    // [true, true, false] => true

    if (Array.isArray(blockerCondition)) {
      const allTrue = checkConditional(blockerCondition, shopper)
      if (allTrue) matchedConditions.push(blockerCondition)
      return allTrue
    } else {
      //check conditional takes array of conditions, if single condition, put it in an array.
      const oneTrue = checkConditional([blockerCondition], shopper)
      if (oneTrue) matchedConditions.push(blockerCondition)
      return oneTrue
    }
  })
  return matchedConditions
}

export const checkConditional = (conditionals: Conditional[], shopper: IShopper): boolean => {
  return conditionals.every((cond: Conditional) => {
    const getValue = new Function('shopper', `return shopper.${cond.element}`)

    if (cond.type === 'array') {
      const val: any[] = getValue(shopper)

      if (!val) return false

      const valueInArray = val.some((v) => {
        if (cond.regexValue) {
          return cond.field ? v[cond.field].match(cond.regexValue) : v.match(cond.regexValue)
        } else if (cond.value) {
          return cond.field ? v[cond.field] === cond.value : v === cond.value
        } else {
          return false
        }
      })

      return cond.matchType === 'valueNotInArray'? !valueInArray : valueInArray
    } else {
      let val = getValue(shopper)

      if (val !== undefined && cond.field) {
        val = val[cond.field]
      }

      if (val === undefined) {
        return cond.value === 'undefined'
      }

      if (cond.regexValue) {
        return val.match(cond.regexValue)
      } else if (cond.value !== undefined) {
        return val === cond.value
      } else {
        console.error('conditional should have "regexValue" or "value" defined')
      }

      return false
    }
  })
}

export function assertNever(x: never, msg: string): never {
  throw new Error(msg)
}

export function getElement(obj: unknown, element: string): unknown {
  if (typeof obj !== 'object' || obj === null) return undefined
  const getValue = new Function('anyobj', `return anyobj.${element}`)
  return getValue(obj)
}

export function getElementAsString(obj: unknown, element: string): string | null | undefined {
  const value = getElement(obj, element)
  const type = typeof value
  if (type === 'string') {
    return value as string
  } else if (type === 'number') {
    return (value as number).toString()
  } else if (type === 'object') {
    return value === null ? null : JSON.stringify(value)
  } else if (type === 'bigint') {
    return (value as bigint).toString()
  } else if (type === 'boolean') {
    return (value as boolean).toString()
  } else if (type === 'symbol') {
    return undefined
  } else if (type === 'undefined') {
    return undefined
  } else if (type === 'function') {
    return undefined
  }
  const exhaustivnessCheck: never = type
  throw new Error(`Return case of "${exhaustivnessCheck}" not handled`)
}

export function getElementAsStringOrNumber(obj: unknown, element: string): string | number | undefined {
  const value = getElement(obj, element)
  if (typeof value === 'string') {
    return value
  } else if (typeof value === 'number') {
    return value
  } else {
    return undefined
  }
}

export function getCatalogItemClassName(itemName: string, itemType: ItemType): string {
  // this function trims trailing/leading whitespace and replaces all special characters/spaces with a hyphen within itemName
  return `${itemType}-${itemName.trim().replace(/([^a-zA-Z0-9-_])|\s/g, '-')}`
}

export function stringifyCircularJSON(obj: any): string {
  const seen = new WeakSet()
  return JSON.stringify(obj, (k, v) => {
    if (v !== null && typeof v === 'object') {
      if (seen.has(v)) return
      seen.add(v)
    }
    return v
  })
}
